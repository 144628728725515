
import AuthMixin from "@/mixins/AuthMixin";
import { SectionData } from "@planetadeleste/vue-mc-learning";
import { Component, Prop, Mixins } from "vue-property-decorator";
import VueCircle from "vue2-circle-progress";

@Component({
  components: { VueCircle },
})
export default class CourseSectionTitle extends Mixins(AuthMixin) {
  @Prop(Object) readonly value!: SectionData;

  get progress(): number {
    if (!this.user) {
      return 0;
    }

    return parseInt(this.$_.get(this.value, "student_progress", 0));
  }
}
